.description-bg {
  background: #000;
  margin-top: 20px;
}
.what-exactly-is-faq {
  color: #fff;
  text-align: center;
  font-family: Roboto;
    font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 40px;
  margin-bottom: 20px;
}
.fire-kirin-is {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-top: 40px;
  padding-left: 200px;
  padding-right: 200px;
}
.existing-features-listings {
  width : 100%;
  margin: auto;
  color: #fff;
  text-align: left;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  padding: 0px 20px;
}
.variety-wrapper {
  padding-top: 64px;
  padding-bottom: 140px;
}
.variety-of-games {
  color: #03fc1c;
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.from-the-fast-paced {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-top: 16px;
}
.official-facebook-pages {
  color: #03fc1c;
  text-align: center;
  font-family: "Metal Mania";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* nov 5 */
.existing-features-container {
  /* margin-top: 16px; */
  color: #fff;
  text-align: center;
  font-family: Roboto, sans-serif; /* Ensure the font-family is applied correctly */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
}
.arrow {
  margin: 0 10px;
  transition: transform 0.3s ease;
  display: inline-block; /* Make sure the arrow can rotate */
}
.faq-container{
  width : 100%;
  padding-bottom: 15px ;
  }
  .question{
    color: white;
    display : flex;
    justify-content : space-between;
    padding : 25px 25px;
    /* background-color: #024d21; */
    background: linear-gradient(90deg, #de2aaf 0%, #fdb82b 100%); 
    text-align: left;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }
  .answer{
    color: rgb(85, 85, 85);
    display : flex;
    justify-content : space-between;
    padding : 25px 25px;
    background-color: #ffffff;
    text-align: left;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* border-radius: 20px; */

  }
  /* .card-start-playing {
    border-radius: 8px !important;
    border: 1px solid #03fc1c !important;
    background: rgba(31, 31, 31, 0.5) !important;
    padding: 22px 23px !important;
    width: 570px !important;
    height: 350px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    display: flex !important;
    gap: 25px !important;
  } */
  .custom-ol {
    padding-left: 1.5rem;
}

.custom-ol li {
    margin-bottom: 0.5rem; /* Adjust the space between items as needed */
    font-size: 20px;
    padding: 10px 0;
}
/* nov 5 end */
.existing-features-container li {
  margin: 8px 0; /* Add margin between list items */
}
.card-gamming {
  border-radius: 8px !important;
  border: 1px solid #FFF !important;
  background: rgba(31, 31, 31, 0.5) !important;
  padding: 22px 23px !important;
  width: 472px !important;
  height: 214px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  display: flex !important;
  gap: 25px !important;
}
.vip-gaming {
  color: #03fc1c;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vip-gaming-is {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}
.facebook-fill {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 158px;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .what-exactly-is-faq {
    padding-top: 103px;
    font-size: 48px;
  }
  .fire-kirin-is {
    padding-left: 0px;
    padding-right: 0px;
  }
  .variety-wrapper {
    padding-top: 0px;
    padding-bottom: 100px;
  }
  .variety-of-games {
    padding-top: 40px;
  }
  .from-the-fast-paced {
    padding-left: 140px;
    padding-right: 140px;
  }
  .official-facebook-pages {
    font-size: 32px;
  }
  .existing-features-container {
    font-size: 20px;
  }
  .card-gamming {
    width: auto !important;
    height: 270px !important;
    padding: 24px !important;
    gap: 10px !important;
  }
  .facebook-fill {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    top: 200px;
  }
}
/* Mobile */
@media only screen and (max-width: 600px) {
  .description-bg {
    padding: 24px 5px;
  }
  .what-exactly-is-faq {
    padding-top: 0px;
    font-size: 24px;
  }
  .fire-kirin-is {
    padding-top: 24px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 18px;
  }
  .variety-wrapper {
    padding-top: 24px;
    padding-bottom: 34px;
  }
  .variety-of-games {
    font-size: 24px;
    padding-top: 16px;
  }
  .from-the-fast-paced {
    font-size: 16px;
  }
  .official-facebook-pages {
    font-size: 32px;
  }
  .existing-features-container {
    font-size: 18px;
    line-height: 25px;
  }
  .card-gamming-wrapper {
    display: flex;
    justify-content: center;
  }
  .card-gamming {
    margin-top: 10px !important;
    width: 340px !important;
    height: auto !important;
    padding: 24px !important;
  }
  .vip-gaming {
    font-size: 20px;
  }
  .vip-gaming-is {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
  }
  .facebook-fill {
    position: static;
  }
  .question{
    font-size: 18px;
    align-items: center;
  }
  .answer{
    font-size: 16px;
  }
  .footer-bg {
    margin-top: 0;
  }
}
.firekirin-bg {
  background: #000;
  padding-top: 50px;
}

.fire-kirin-fish {
  color: #fff;
  font-family: "Metal Mania";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fire-kirin-offers {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 24px;
}

.fire-kirin-image {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .fire-kirin-fish {
    text-align: center;
    font-size: 48px;
  }
  .fire-kirin-offers {
    margin-top: 24px;
    text-align: center;
  }
  .fire-kirin-image {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
/* .faq-item {
    cursor: pointer;
    margin-bottom: 10px;
}

.faq-question {
    display: flex;
    align-items: center;
} */

.arrow {
    margin-right: 10px;
    transition: transform 0.3s ease;
    font-weight: bold; /* Make the arrow bold */
}

.expanded {
    transform: rotate(90deg); /* Optional: rotate the arrow to indicate expansion */
}

.faq-answer {
    display: block;
    margin-top: 5px;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .firekirin-bg {
    padding-top: 3px;
  }
  .fire-kirin-fish {
    text-align: center;
    font-size: 32px;
  }
  .fire-kirin-offers {
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  .fire-kirin-image {
    height: 260px;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
