* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "roboto", sans-serif;
}

.app-bg {
  background: #000;
}


@media only screen and (max-width: 600px) {

  html,body
  {
      width: 100%;
      height: 100%;
      margin: 0px;
      padding: 0px;
      /* overflow-x: hidden;  */
  }

}