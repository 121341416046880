* {
  line-height: 1.7;
}
.section__text {
  width: 100%;
  line-height: 1.7;
}
.section__arrowText {
  width: 100%;
  line-height: 1.7;
  display: flex;
  gap: 8px;
}
.section__arrowText .arrowText__key {
  font-weight: 500;
}
.section__image {
  width: 100%;
  padding: 16px;
}
.section__image img {
  width: 100%;
}
.section__numberText,
.section__bulletText {
  width: 100%;
  display: flex;
  gap: 8px;
  margin-bottom: 10px;

}
.section__bulletText .bulletText__keyValue .bulletText__key {
  padding-right: 8px;
  font-weight: 500;
}
.section__subTitle {
  margin-top: 16px;
}
.section__Title {
  margin-top: 24px;
}
