.nav-logo {
  width: 148px;
  height: auto;
}

.navbar {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  margin-left: 50px;
  margin-right: 50px;
}

.nav-font {
  color: #fff !important;
  font-family: Roboto !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("../../public/assets/menu-line.svg");
}

.button--joinNow {
  background: #cb39c9;
  border: none !important;
  border-radius: 50px;
  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  line-height: 20px;
  padding: 2px 4px;
  text-align: center;
}

.activeLink {
  text-decoration: underline;
  text-underline-offset: 5px;
}

/* Desktop */
@media only screen and (min-width: 1024px) {
}

@media only screen and (max-width: 991px) {
  .button--joinNow {
    width: fit-content;
  }
  .button--joinNow .nav-link {
    color: #fff !important;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .navbar {
    margin-left: 0px;
    margin-right: 0px;
  }
}
