html {
  scroll-behavior: smooth;
}
.blogPage__container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 164px);
  background: black;
  color: white;
  padding-inline: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blogPage__header {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
}
.blogPage__header h1 {
  font-size: 50px;
  font-weight: 500;
  text-align: center;
}
.blogPage__header img {
  width: 100%;
}
.blogPage__body {
  width: 80%;
  display: flex;
  gap: 20px;
  padding-top: 32px;
  padding-bottom: 16px;
}
.blogPage__body--left {
  width: 75%;
}
.Allsections {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blogPage__body--right {
  width: 25%;
  height: calc(100vh - 164px);
}
.right__container {
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.blogPage__recentPosts,
.blogPage__shareBox,
.blogPage__contentBox {
  border: 1px solid #cb39c9;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.blogPage__contentListing .blogPage__content button{
 background: transparent;
 border: none;
  color: white;
  display: flex;
  gap: 8px;
}
.blogPage__recentPosts {
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
}
.blogPage__recentPosts .card--recentPost {
  cursor: pointer;
}
.blogPage__shareBox > div {
  display: flex;
  gap: 16px;
}
.blogPage__shareBox > div button {
  background: black;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 30px;
  text-align: center;
  width: 30px;
}
.blogPage__shareBox > div button:last-child img {
  width: 70%;
  height: 70%;
}

.blogPage__body--right {
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 20px;
  position: sticky;
  top: 200px;
}
.blogPage__body--right::-webkit-scrollbar,
.blogPage__recentPosts::-webkit-scrollbar {
  display: none;
}
.blogPage__contentListing .blogPage__content button span{
  text-align: start;
}

@media screen and (max-width: 1400px) {
  .blogPage__body--left {
    width: 65%;
  }
  .blogPage__body--right {
    width: 35%;
  }
  .blogPage__recentPosts .card--recentPost span {
    font-size: 14px;
  }
}
@media screen and (max-width: 776px) {
  .blogPage__header {
    width: 100%;
    padding: 16px;
  }
  .blogPage__body {
    width: 100%;
    padding: 16px;
  }
  .blogPage__body--left {
    width: 60%;
  }
  .blogPage__body--right {
    width: 40%;
  }
  .blogPage__contentBox {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .blogPage__body--left {
    width: 50%;
  }
  .blogPage__body--right {
    width: 50%;
  }
  .blogPage__body,
  .blogPage__header {
    padding: 8px;
  }
}
