.disclaimer__container {
  width: 80%;
  height: 100%;
  min-height: calc(100vh - 164px);
  background: black;
  color: white;
  padding-inline: 16px;
  box-sizing: border-box;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 1.7;
}
.disclaimer__container h1 {
  text-align: center;
}
.disclaimer__container span {
  font-weight: 500;
  margin-top: 16px;
}

@media screen and (max-width: 600px) {
  .disclaimer__container {
    width: 100%;
    padding-inline: 16px;
  }
}
