.description-bg {
  background-color: #121212;
  color: white;
  padding: 60px 0;
}

.what-exactly-is-exclusive {
  font-size: 2.5rem;
  font-weight: bold;
}

.variety-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.variety-wrapper-1{
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.feature-card {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
}

.feature-icon {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 10px;
}

.feature-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 1rem;
  line-height: 1.6;
}

@media screen and (max-width: 600px) {
  .what-exactly-is-exclusive {
    font-size: 24px;
  }
  .what-exactly-is-des {
    font-size: 24px;
}
.feature-title {
  font-size: 20px;
}

}