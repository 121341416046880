.blogs__container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 164px);
  background: black;
  color: white;
  padding-inline: 16px;
  box-sizing: border-box;
}
.blogs__header {
  font-size: 50px;
  display: flex;
  justify-content: center;
  font-weight: 500;
}
.blogs__body {
  height: 100%;
  padding: 16px;
  display: flex;
  gap: 16px;
  width: 90%;
  margin-inline: auto;
}
.blogs__body--left {
  width: 75%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 325px));
  gap: 24px;
  place-items: center;
  padding: 16px;
  padding-inline: 32px;
}
.blogs__body--right {
  width: 30%;
  max-width: 100vh;
  overflow-y: auto;
}
.card--blog {
  width: 100%;
  border: 1px solid #cb39c9;
  box-shadow: 0 0 17px 0 #cb39c9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.card__header--blog img {
  width: 100%;
  height: auto;
}
.card__body--blog {
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-bottom: 32px;
}
.card__body--blog span {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.button--blogCard {
  background: #cb39c9;
  border: none;
  border-radius: 35px;
  box-shadow: 0 0 17px 0 #cb39c9;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 24px;
  transition: 0.3s;
}
.button--blogCard:hover {
  background: white;
  color: black;
}
.blogs__body--right {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #cb39c9;
  height: fit-content;
  padding: 24px;
}
.card--recentPost {
  width: 100%;
  cursor: pointer;
}
.blogs__body__title {
  font-size: 25px;
  font-weight: 500;
}
.card__body--recentPost {
  display: flex;
  gap: 16px;
  align-items: center;
}
.card__body--recentPost img {
  width: 100px;
}

@media screen and (max-width: 1400px) and (min-width: 1100px) {
  .blogs__body--left {
    width: 70%;
    grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  }
  .blogs__body--right {
    width: 30%;
  }
  .card__body--blog span {
    font-size: 20px;
  }
  .card--recentPost .card__body--recentPost span {
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  .blogs__body--left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  .blogs__body--left .card--blog {
    min-width: 250px;
    max-width: 300px;
  }
  .blogs__body--right {
    width: 40%;
  }
  .blogs__body--left {
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  .blogs__body {
    width: 100%;
    padding: 0;
    padding-right: 32px;
  }
  .blogs__body--left {
    padding-inline: 0;
    padding: 0;
  }
  .blogs__body--left .card--blog {
    min-width: 200px;
    max-width: 250px;
  }
  .card--recentPost .card__body--recentPost {
    flex-direction: column;
  }
  .card--recentPost .card__body--recentPost span {
    text-align: center;
  }
  .card__body--blog span {
    font-size: 18px;
  }
  .card--recentPost .card__body--recentPost span {
    font-size: 13px;
  }
}
@media screen and (max-width: 550px) {
  .blogs__body {
    flex-direction: column-reverse;
    padding-right: 0;
  }
  .blogs__body--right {
    width: 90%;
    height: 200px;
    margin-inline: auto;
  }
  .blogs__body--right {
    flex-direction: row;
  }
  .blogs__body--left {
    width: 100%;
  }
  .blogs__body--left .card--blog {
    max-width: 300px;
  }
}
