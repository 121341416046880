.banner-container {
  width: 90%;
  max-width: 1064px;
  margin: 0 auto;
  margin-top: 96px;
}

.banner-container-two {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  width: 1071px;
  padding: 0 30px;
  box-sizing: border-box;
  gap: 24px;
}

.banner-online {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}

.banner-orion-star {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 24px;
}

.banner-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.banner-button {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border-radius: 2.25rem;
  background: linear-gradient(90deg, #de2aaf 0%, #fdb82b 100%);
  box-shadow: 4px 4px 50px 0px #b3295f;
  padding: 12px 24px;
  border: none;
}

.banner-so-whats {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 40px;
}

.banner-download {
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
}

.banner-text-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 133px;
  gap: 20px;

  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 40px;
}

.banner-container-three {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  width: 1000px;
  padding: 0 30px;
  box-sizing: border-box;
  gap: 24px;
}

.now-play {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.signup-txt {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-img-bg {
  background: url("../../public/assets/banner-bg.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 50vh;
  height: auto;
  aspect-ratio: 16/9;
  margin-top: 40px;
  /* height: auto; */
  /* min-height: 300px; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 20px; */
  /* box-sizing: border-box; */
  
}

.banner-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.banner-img img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .banner-online {
    font-size: 40px;
    letter-spacing: -0.8px;
  }
  .banner-container-three {
    width: 500px;
  }
  .now-play {
    font-size: 24px;
  }
  .signup-txt {
    font-size: 24px;
  }
}

.background-container{
  background: linear-gradient(
    rgba(0, 0, 0, 0.4), /* Dark transparent gradient */
    rgba(0, 0, 0, 0.4) /* Dark transparent gradient */
),
url(http://localhost:3000/static/media/banner-bg.e0e77dadf6f4ae4c20e7.svg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
padding: 50px 0 230px 0;

}

/* Mobile */
@media only screen and (max-width: 600px) {
  .banner-img-bg {
    background-size: cover; /* Adjusts to smaller screens */
  }
  .banner-online {
    font-size: 24px;
    letter-spacing: -0.48px;
  }
  .now-play {
    font-size: 18px;
  }
  .signup-txt {
    font-size: 18px;
  }

  .background-container{
    background: linear-gradient(
      rgba(0, 0, 0, 0.4), /* Dark transparent gradient */
      rgba(0, 0, 0, 0.4) /* Dark transparent gradient */
  ),
  url(http://localhost:3000/static/media/banner-bg.e0e77dadf6f4ae4c20e7.svg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  padding: 80px 0;
  
  }
}
