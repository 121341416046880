@keyframes jump {
  0%,
  40% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.chat__container {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 100px;
  right: 20px;
  animation: jump 3s infinite;
}
.chat__container button {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
}
.chat__container button img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .chat__container {
    width: 40px;
    height: 40px;
    bottom: 70px;
  }
}
