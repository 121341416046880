.scrollToTop__button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px 12px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #cb39c9;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .scrollToTop__button {
    width: 40px;
    height: 40px;
  }
}
