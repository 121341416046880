.cards-bg {
  margin-top: 98px;
  /* background-color: #000; */
}

.card-checkout {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  margin-bottom: 38px;
}

.card-override {
  background: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 12px !important;
}

.card-image {
  padding: 5px;
  border-radius: 8px;
}

.card-button {
  border: none;
  height: 80px;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(90deg, #de2aaf 0%, #fdb82b 100%);
  box-shadow: 4px 4px 50px 0px #b3295f;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.official-facebook-pages {
  color: #fff !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 48px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.please-contact-our-container {
  margin-top: 16px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-gamming {
  border-radius: 8px !important;
  border: 1px solid #777 !important;
  background: #1f1f1f !important;
  padding: 22px 23px !important;
  width: 472px !important;
  height: 214px !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  display: flex !important;
  gap: 25px !important;
}

.vip-gaming {
  color: #fff !important;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  cursor: pointer;
}

.facebook-fill-gv {
  cursor: poRoboto;
  box-sizing: border-box;
  display: flex;
}

.facebook-fill-gv img {
  width: 50px;
  height: 50px;
}
.vip-gaming-is {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.card-override {
  position: relative;
  overflow: hidden;
}

.card-override .card__hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.card-override:hover .card__hover {
  opacity: 1;
  visibility: visible;
}

.card-override .card__hover button {
  width: 90%;
  padding: 16px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(90deg, #de2aaf 0%, #fdb82b 100%);
  color: white;
  font-size: 28px;
  cursor: pointer;
  transform: scale(0.9); /* Slightly smaller */
  transition: transform 0.3s ease, background-color 0.3s ease;
  font-weight: 700;
}

.card-override .card__hover button:hover {
  transform: scale(1);
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .card-checkout {
    font-size: 40px;
    letter-spacing: -0.8px;
  }
  .vip-gaming {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 900px) {
  .official-facebook-pages {
    font-size: 24px;
  }
  .please-contact-our-container {
    font-size: 20px;
  }
  .card-gamming {
    width: auto !important;
    height: 270px !important;
    padding: 24px !important;
    gap: 10px !important;
  }
  .facebook-fill-gv {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .facebook-fill-gv {
    top: 150px;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .cards-bg {
    margin-top: 40px;
  }

  .card-checkout {
    font-size: 24px;
    letter-spacing: -0.48px;
    margin-bottom: 24px;
  }

  .official-facebook-pages {
    font-size: 20px;
  }

  .please-contact-our-container {
    font-size: 18px;
    line-height: 25px;
  }
  .FB-row {
    margin-bottom: 30px;
  }
  .card-gamming-wrapper {
    display: flex;
    justify-content: center;
  }
  .card-gamming {
    margin-top: 10px !important;
    width: 340px !important;
    height: auto !important;
    padding: 24px !important;
  }
  .vip-gaming {
    font-size: 20px;
  }
  .facebook-fill-gv {
    position: static;
  }
  .vip-gaming-is {
    font-size: 14px;
    line-height: 24px;
    text-align: start;
  }
}
