.privacyPolicy__container {
  width: 80%;
  height: 100%;
  min-height: calc(100vh - 164px);
  background: black;
  color: white;
  padding-inline: 16px;
  box-sizing: border-box;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 1.7;
}
.privacyPolicy__container h1 {
  text-align: center;
}
.privacyPolicy__container h2 {
  margin-top: 16px;
}
.textWithBullets__container .textWithBullets--keyValue > span:first-child {
  font-weight: 500;
  padding-right: 8px;
}
.textWithBullets__container
  .textWithBullets--keyValue
  > span:first-child::before {
  content: "•";
  margin-right: 8px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}
.textWithBullets__container .textWithBullets::before {
  content: "•";
  margin-right: 8px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}
.textWithNumbers {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.textWithNumbers,
.textWithBullets__container {
  padding-inline: 20px;
}

@media screen and (max-width: 600px) {
  .privacyPolicy__container {
    width: 100%;
    padding-inline: 16px;
  }
}
